<template>
  <article class="traffic-permit-container">
    <div class="frame">
      <header class="traffic-permit-header">
        <div class="logo-id">
          <div class="logo-group">
            <div class="logo-t">
              <img src="../img/JCDClogo.png" alt="" />
            </div>
            <div class="logo-s">
              <img src="../img/CHEClogo.png" alt="" />
            </div>
          </div>
          <div class="ID">
            ID: {{ data.passport_number ? data.passport_number : "-" }}
          </div>
        </div>
        <div class="title-logo">
          <div class="title">Temporary Pass for Materials</div>
          <div class="logo">
            <img src="../img/blueTitleDecoration.png" alt="" />
          </div>
        </div>
      </header>
      <div class="message-container text-style">
        <div class="validity-lssuing">
          <div class="validity-period">
            Validity Period:&nbsp;&nbsp;{{ validityPeriod }}
          </div>
          <div class="desc">
            Issuing Unit:&nbsp;&nbsp;{{
              data.Issuing_unit_abbreviation_name
                ? data.Issuing_unit_abbreviation_name
                : "-"
            }}
          </div>
        </div>
        <ul class="message">
          <li
            class="message-column"
            v-for="(child, index) in messageData"
            :key="index"
          >
            <div class="item-key">
              {{ child.key ? child.key : "-" }}&nbsp;&nbsp;
            </div>
            <div
              class="item-value"
              :style="child.key === 'Contact Phone Number:'? 'overflow: visible !important;':''"
              :class="
                ['Vehicle Type:', 'Contact Phone Number:'].includes(child.key)
                  ? 'go-beyond'
                  : 'go-beyond-two'
              "
            >
              {{ child.value ? child.value : "-" }}
            </div>
          </li>
        </ul>
      </div>
      <div class="item-information text-style">
        <div class="item-key">Construction Resources Details:&nbsp;&nbsp;</div>
        <div class="information-value">
          {{ data.Item_information ? data.Item_information : "-" }}
        </div>
      </div>
      <div class="item-photos text-style">
        <div class="item-key">Construction Resources Photos:&nbsp;&nbsp;</div>
        <div class="photos-container">
          <div class="photos-group" v-if="data?.Item_photos?.[0]?.url">
            <img :src="data?.Item_photos?.[0]?.url" alt="" />
          </div>
          <div class="photo-ID">
            <img v-if="data.st_qr_code" :src="data.st_qr_code" alt="" />
          </div>
        </div>
      </div>
    </div>
  </article>
</template>
<script>
import { dataInterface } from '@/apis/data';

export default {
  name: 'index',
  components: {},
  props: [],
  data () {
    return {
      messageData: [
        {
          key: 'Associated Company:',
          value: ''
        },
        {
          key: 'Plate Number:',
          value: ''
        },
        {
          key: 'Applicant Name:',
          value: ''
        },
        {
          key: "Manufacturer:",
          value: ''
        },
        {
          key: 'Identification Number:',
          value: ''
        },
        {
          key: 'Vehicle Type:',
          value: ''
        },
        {
          key: 'Contact Phone Number:',
          value: ''
        }
      ],
      data: {}
    }
  },
  computed: {
    validityPeriod () {
      const startDate = this.data.effective_date?.split('-')?.reverse()?.join('/');
      const endDate = this.data.expiration_date?.split('-')?.reverse()?.join('/');
      return `${startDate} - ${endDate}` || '-';
    }
  },
  created () {
    this.getData();
  },
  methods: {
    /**
     * @desc: 获取列表
     */
    getData () {
      const dataId = window.location.href.split('?data_id=')?.[1];
      dataInterface({
        object_uuid: 'object660bece42a560',
        view_uuid: 'view661f46994d41c',
        __method_name__: 'dataInfo',
        data_id: dataId,
        transcode: 0
      }).then((res) => {
        this.data = res.data.data;
        this.messageData.forEach(item => {
          item.value = this.data[this.getKey(item.key)] ? this.data[this.getKey(item.key)] : '-';
        })
      })
    },
    getKey (key) {
      switch (key) {
        case 'Associated Company:':
          return 'Issuing_unit_abbreviation_name';
        case 'Plate Number:':
          return 'vehicle_number_show';
        case 'Applicant Name:':
          return 'driver_name';
        case "Manufacturer:":
          return 'vehicle_brand_name';
        case 'Identification Number:':
          return 'passport_code';
        case 'Vehicle Type:':
          return 'vehicle_type_name';
        case 'Contact Phone Number:':
          return 'phone_number';
        default: '-';
      }
    }
  }
}
</script>
<style lang="less" scoped>
.traffic-permit-container {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url("../img/blueBorder.png");
  background-position: 100% 100%;
  background-size: 100% 100%;
  img {
    width: 100%;
    height: 100%;
  }
  .frame {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-position: 100% 100%;
    background-size: 100% 100%;
    z-index: 10;
    .traffic-permit-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 305px 420px 0px 413px;
      box-sizing: border-box;
      .logo-id {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 150px;
        .logo-group {
          display: inline-flex;
          align-items: flex-start;
          justify-content: space-between;
          gap: 16px;
          width: 508px;
          height: 100%;
          border-radius: 12px;
          .logo-t {
            width: 280px;
            height: 100%;
          }
          .logo-s {
            width: 212px;
            height: 100%;
          }
        }
        .ID {
          flex: 1;
          color: #0DA0C3;
          font-family: "Microsoft YaHei";
          font-size: 60px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-align: right;
          white-space: nowrap;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          -o-text-overflow: ellipsis;
        }
      }
      .title-logo {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        .title {
          display: flex;
          justify-content: center;
          width: 100%;
          margin-top: 78px;
          color: #0DA0C3;
          font-family: "Microsoft YaHei";
          font-size: 125px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          white-space: nowrap;
        }
        .logo {
          width: 1396px;
          height: 162px;
          margin: 31px 0 112px 0;
        }
      }
    }
    .message-container {
      width: 100%;
      padding: 0 320px 0 360px;
      box-sizing: border-box;
      .validity-lssuing {
        text-align: center;
        .validity-period {
          padding-bottom: 24px;
          box-sizing: border-box;
        }
        .desc {
          width: 100%;
          height: 132px !important;
          word-break: break-all;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .message {
        display: grid;
        grid-template-columns: repeat(2, 880px);
        row-gap: 32px;
        column-gap: 40px;
        width: 100%;
        margin-top: 60px;
        .message-column {
          display: flex;
          width: 100%;
          height: 132px;
          overflow: hidden;
          &:first-child {
            grid-column-start: 1;
            grid-column-end: 3;
            grid-column: 1/5;
          }
          &:last-child {
            height: 66px;
          }
          &:nth-last-child(2) {
            height: 66px;
          }
          .item-key {
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
          }
          .go-beyond-two {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            text-overflow: ellipsis;
          }
          .go-beyond {
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .item-value {
            flex: 1;
            height: 100%;
            overflow: hidden;
            word-break: break-all;
          }
        }
      }
    }
    .text-style {
      color: #0DA0C3;
      font-family: "Microsoft YaHei";
      font-size: 50px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.1px;
    }
    .item-information {
      overflow: hidden;
      margin-top: 60px;
      padding: 0 320px 0 360px;
      box-sizing: border-box;
      .item-key {
        padding: 38px 0 32px 0;
        box-sizing: border-box;
      }
      .information-value {
        flex: 1;
        height: 265px;
        color: #0DA0C3;
        font-family: "Microsoft YaHei";
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: -webkit-box;
        word-break: break-all;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .item-photos {
      margin-top: 98px;
      padding: 0 320px 0 360px;
      box-sizing: border-box;
      .item-key {
        padding-bottom: 32px;
        box-sizing: border-box;
      }
      .photos-container {
        display: flex;
        align-items: center;
        gap: 200px;
        height: 582px;
        .photos-group {
          width: 960px;
          height: 582px;
          border-radius: 12px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 12px;
            object-fit: revert-layer;
          }
        }
        .photo-ID {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 480px;
          height: 480px;
          background-image: url("../img/ORCodet.png");
          background-position: 100% 100%;
          background-size: 100% 100%;
          z-index: 10;
          img {
            width: 420px;
            height: 420px;
            border-radius: 8px;
            object-fit: revert-layer;
          }
        }
      }
    }
  }
}
</style>
